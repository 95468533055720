import { call, delay, put, PutEffect, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import axios, { AxiosError } from "axios";

import { createAxiosRequest, createDeleteAxiosRequest, createPostAxiosRequest } from "../../utils";
import {
    ADD_PODCAST_TO_USER,
    ADD_PODCAST_TO_USER_FAILED,
    ADD_PODCAST_TO_USER_SUCCESS,
    ADD_PODCASTER_PERMISSION,
    ADD_PODCASTER_PERMISSION_FAILED,
    ADD_PODCASTER_PERMISSION_SUCCESS,
    AddPodcasterPermission,
    AddPodcastToUser,
    CANCEL_SUBSCRIPTION,
    CANCEL_SUBSCRIPTION_FAILED,
    CANCEL_SUBSCRIPTION_SUCCESS,
    CancelSubscription,
    DELETE_PODCAST_FROM_USER,
    DELETE_PODCAST_FROM_USER_FAILED,
    DELETE_PODCAST_FROM_USER_SUCCESS,
    DELETE_PODCASTER_PERMISSION,
    DELETE_PODCASTER_PERMISSION_FAILED,
    DELETE_PODCASTER_PERMISSION_SUCCESS,
    DELETE_USER,
    DELETE_USER_FAILED,
    DELETE_USER_SUCCESS,
    DeletePodcasterPermission,
    DeletePodcastFromUser,
    DeleteUser,
    GET_USER_DETAILS,
    GET_USER_DETAILS_FAILED,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_ROLE,
    GET_USER_ROLE_FAILED,
    GET_USER_ROLE_SUCCESS,
    GET_USERNAME,
    GET_USERNAME_FAILED,
    GET_USERNAME_SUCCESS,
    GET_USERS,
    GET_USERS_FAILED,
    GET_USERS_SUCCESS,
    GetUser,
    GetUsers,
    LOGIN_WITH_SCHIBSTED,
    LOGIN_WITH_SCHIBSTED_FAILED,
    LoginWithSchibsted,
    LoginWithSchibstedFailed,
    REFRESH_SUBSCRIPTIONS,
    REFRESH_SUBSCRIPTIONS_FAILED,
    REFRESH_SUBSCRIPTIONS_SUCCESS,
    RefreshSubscriptions,
    SEARCH_USER,
    SEARCH_USER_FAILED,
    SEARCH_USER_SUCCESS,
    SearchUser,
    UNMIGRATE,
    Unmigrate,
    UNMIGRATE_FAILED,
    UNMIGRATE_SUCCESS,
    UPDATE_PODCAST_STATISTICS_PERMISSION,
    UPDATE_PODCAST_STATISTICS_PERMISSION_FAILED,
    UPDATE_PODCAST_STATISTICS_PERMISSION_SUCCESS,
    UpdatePodcastStatisticsPermission,
    GetSchibstedToken,
    GET_USER_QUERY_TYPES_SUCCESS,
    GET_USER_QUERY_TYPES_FAILED,
    GET_USER_QUERY_TYPES,
    GET_SCHIBSTED_TOKEN,
    RefreshSchibstedToken,
    REFRESH_SCHIBSTED_TOKEN,
    FETCH_SUBSCRIPTION_EVENTS,
    FetchSubscriptionEventsSuccess,
    FetchSubscriptionEventsFailed,
    FetchSubscriptionEvents,
    FETCH_SUBSCRIPTION_EVENTS_SUCCESS,
    FETCH_SUBSCRIPTION_EVENTS_FAILED,
    SubscriptionEvent,
    DeleteUserSuccess,
    RefreshSubscriptionsSuccess,
    CancelSubscriptionSuccess,
    DeleteUserFailed,
    CancelSubscriptionFailed,
    UserDetails,
    MIGRATE_USER_FAILED,
    MIGRATE_USER_SUCCESS,
    MIGRATE_USER,
    MigrateUser,
    TOGGLE_SOFT_MIGRATION,
    TOGGLE_SOFT_MIGRATION_SUCCESS,
    TOGGLE_SOFT_MIGRATION_FAILED,
    ToggleSoftMigration,
    ToggleSoftMigrationFailed,
    ToggleSoftMigrationSuccess,
    GET_SOFT_MIGRATION_STATUS,
    GetSoftMigrationStatus,
    GetSoftMigrationStatusSuccess,
    GetSoftMigrationStatusFailed,
    GET_SOFT_MIGRATION_STATUS_SUCCESS,
    GET_SOFT_MIGRATION_STATUS_FAILED,
    MigrateUserSuccess,
    MigrateUserFailed,
    GetUserFailed,
    GetUserSuccess,
    START_FETCH_SUBSCRIPTION_EVENTS_SEQUENCE,
    StartFetchSubscriptionEventsSequence,
    FetchPaymentTransactionsSuccess,
    FETCH_PAYMENT_TRANSACTIONS_SUCCESS,
    FETCH_PAYMENT_TRANSACTIONS_FAILED,
    FetchPaymentTransactionsFailed,
    FETCH_PAYMENT_TRANSACTIONS,
    FetchPaymentTransactions,
    START_FETCH_PAYMENT_TRANSACTIONS_SEQUENCE,
    StartFetchPaymentTransactionsSequence,
    AddFreeSubscriptionValues,
    ADD_FREE_SUBSCRIPTION,
    ADD_FREE_SUBSCRIPTION_FAILED,
    ADD_FREE_SUBSCRIPTION_SUCCESS,
    REFUND_TRANSACTION,
    REFUND_TRANSACTION_SUCCESS,
    REFUND_TRANSACTION_FAILED,
    RefundTransactionSuccess,
    RefundTransactionFailed,
    RefundTransaction,
    StartFetchSubscriptionGuestHistorySequence,
    FetchSubscriptionGuestHistory,
    FETCH_SUBSCRIPTION_GUEST_HISTORY,
    START_FETCH_SUBSCRIPTION_GUEST_HISTORY_SEQUENCE,
    FETCH_SUBSCRIPTION_GUEST_HISTORY_SUCCESS,
    FETCH_SUBSCRIPTION_GUEST_HISTORY_FAILED,
    FetchSubscriptionGuestHistorySuccess,
    FetchSubscriptionGuestHistoryFailed,
} from "./types";

import types, { ApiError, LOGOUT_USER, SagaReturnType, USER_IS_NOT_LOGGED_IN } from "../../redux/types";

import { identity as schibstedIdentity, init as initSchibsted } from "../../config/schibsted-init";
import Cookies from "universal-cookie";
import config from "../../config";
import { RootState } from "../../redux/reducers/rootReducer";

const usersSelector = (state: RootState) => state.manageUsers;

function* getUsername(): any {
    try {
        const { data } = yield createAxiosRequest(`api/user-account/username`);

        return yield put({ type: GET_USERNAME_SUCCESS, username: data });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error: AxiosError = e.response;
            return yield put({ type: GET_USERNAME_FAILED, message: error.message, status: error.response?.status });
        }
    }
}

function* getUsers({ pageSize, currentPage }: GetUsers): any {
    try {
        const { data } = yield createAxiosRequest(
            `api/user/users?pageSize=${pageSize}${currentPage ? `&currentPage=${currentPage}` : 0}`
        );

        return yield put({ type: GET_USERS_SUCCESS, users: data });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error: AxiosError = e.response;
            return yield put({ type: GET_USERS_FAILED, message: error.message, status: error.response?.status });
        }
    }
}

function* getUser({ userId }: GetUser): SagaReturnType<PutEffect<GetUserSuccess | GetUserFailed>> {
    try {
        const { data } = yield createAxiosRequest(`api/user/${userId}`);

        return yield put({ type: GET_USER_DETAILS_SUCCESS, user: { ...data } });
    } catch (e) {
        const errorResponse = e && e.response ? new ApiError(e.response) : e;
        const defaultMessage = errorResponse.status === 404 ? "User not found." : "Could fetch user details.";
        const message = getErrorMessage(e, defaultMessage);
        const status = errorResponse.status || 500;

        return yield put<GetUserFailed>({
            type: GET_USER_DETAILS_FAILED,
            message,
            showToastErrorMessage: true,
            status,
        });
    }
}

function* searchUser({ searchQuery, searchType, context }: SearchUser): any {
    try {
        if (searchQuery.length > 100) {
            return yield put({ type: SEARCH_USER_FAILED, message: "Search query cannot be more than 100 characters", context });
        }

        let response;
        if (searchType) {
            response = yield call(
                createAxiosRequest,
                `api/user/search-with-query-type?searchQuery=${searchQuery}&searchQueryType=${searchType}`
            );
        } else {
            response = yield call(createAxiosRequest, `api/user/search?searchQuery=${searchQuery}`);
        }

        yield put({ type: SEARCH_USER_SUCCESS, users: response.data, context });
    } catch (e) {
        const error: AxiosError = e.response;
        yield put({ type: SEARCH_USER_FAILED, message: error?.message, status: error.response?.status, context });
    }
}

function* getUserQueryTypes(): any {
    try {
        const { data } = yield createAxiosRequest("api/user-account/get-search-query-types");

        return yield put({ type: GET_USER_QUERY_TYPES_SUCCESS, queryTypes: data });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error: AxiosError = e.response;
            return yield put({ type: GET_USER_QUERY_TYPES_FAILED, message: error?.message, status: error.response?.status });
        }
    }
}

function* deleteUser({ userAccountId }: DeleteUser): any {
    try {
        yield delay(1000);
        yield call(createDeleteAxiosRequest, `api/user-account/${userAccountId}`);

        const state = yield select(usersSelector);
        const { accounts }: UserDetails = state.user;
        let toastMessage = "User deleted successfully";

        // Length of 1 means that this is the last user account from state that's about to be deleted
        const noUserAccountsLeft = accounts.length === 1;

        if (noUserAccountsLeft) toastMessage = "No user accounts left. User entity deleted successfully.";

        return yield put<DeleteUserSuccess>({
            type: DELETE_USER_SUCCESS,
            message: toastMessage,
            showToastSuccessMessage: true,
            deletedUserAccountId: userAccountId,
        });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error: AxiosError = e.response;
            return yield put<DeleteUserFailed>({
                type: DELETE_USER_FAILED,
                message: error?.message,
                showToastErrorMessage: true,
                deletedUserAccountId: userAccountId,
            });
        }
    }
}

function* getUserRole(): any {
    try {
        const { data } = yield call(createAxiosRequest, `api/user-account/role`);
        if (data) {
            return yield put({ type: GET_USER_ROLE_SUCCESS, userRole: data });
        }

        return yield put({ type: LOGOUT_USER });
    } catch (e) {
        if (e && e.message && e.response) {
            const error: AxiosError = e.response;
            return yield put({ type: GET_USER_ROLE_FAILED, message: error?.message, status: error.response?.status });
        } else {
            return yield put({ type: USER_IS_NOT_LOGGED_IN, message: "No error response", status: 400 });
        }
    }
}

function* cancelSubscription({ userAccountId, podcastId }: CancelSubscription): any {
    try {
        if (podcastId) {
            yield call(createDeleteAxiosRequest, `api/subscription/podcast/${userAccountId}/${podcastId}`);
        } else {
            yield call(createDeleteAxiosRequest, `api/subscription/package/${userAccountId}`);
        }

        return yield put<CancelSubscriptionSuccess>({
            type: CANCEL_SUBSCRIPTION_SUCCESS,
            cancelledSubscription: podcastId,
            message: "Subscription cancelled successfully",
            showToastSuccessMessage: true,
        });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error: AxiosError = e.response;
            return yield put<CancelSubscriptionFailed>({
                type: CANCEL_SUBSCRIPTION_FAILED,
                message: error?.message,
                showToastErrorMessage: true,
            });
        }
    }
}

function* addPodcasterPermission({ userAccountId }: AddPodcasterPermission): any {
    try {
        yield call(createAxiosRequest, `api/user-account/${userAccountId}/grantPodcasterPermission`, "", "patch");

        return yield put({ type: ADD_PODCASTER_PERMISSION_SUCCESS });
    } catch (e) {
        if (e && e.stack && e.message && e.response) {
            const error: AxiosError = e.response;
            return yield put({
                type: ADD_PODCASTER_PERMISSION_FAILED,
                message: error?.message,
                status: error.response?.status,
            });
        }
    }
}

function* deletePodcasterPermission({ userAccountId }: DeletePodcasterPermission): any {
    try {
        yield call(createAxiosRequest, `api/user-account/${userAccountId}/deletePodcasterPermission`, "", "patch");

        return yield put({ type: DELETE_PODCASTER_PERMISSION_SUCCESS });
    } catch (e) {
        if (e && e.stack && e.message && e.response) {
            const error: AxiosError = e.response;
            return yield put({
                type: DELETE_PODCASTER_PERMISSION_FAILED,
                message: error?.message,
                status: error.response?.status,
            });
        }
    }
}

function* updatePodcastStatisticsPermission({ userAccountId, podcastId, value }: UpdatePodcastStatisticsPermission): any {
    try {
        yield call(createAxiosRequest, `api/podcast-permission/${userAccountId}/${podcastId}/${value}`, "", "patch");

        return yield put({ type: UPDATE_PODCAST_STATISTICS_PERMISSION_SUCCESS, podcastId, value });
    } catch (e) {
        if (e && e.stack && e.message && e.response) {
            const error: AxiosError = e.response;
            return yield put({
                type: UPDATE_PODCAST_STATISTICS_PERMISSION_FAILED,
                message: error?.message,
                status: error.response?.status,
            });
        }
    }
}

function* deletePodcastFromUser({ userAccountId, podcastId }: DeletePodcastFromUser): any {
    try {
        yield call(createAxiosRequest, `api/podcast-permission/${userAccountId}/${podcastId}/`, "", "delete");

        return yield put({ type: DELETE_PODCAST_FROM_USER_SUCCESS, podcastId });
    } catch (e) {
        if (e && e.stack && e.message && e.response) {
            const error: AxiosError = e.response;
            return yield put({
                type: DELETE_PODCAST_FROM_USER_FAILED,
                message: error?.message,
                status: error.response?.status,
            });
        }
    }
}

function* addUserToPodcast({ userAccountId, podcastId }: AddPodcastToUser): any {
    try {
        const { data } = yield call(createAxiosRequest, `api/podcast-permission/${userAccountId}/${podcastId}/`, "", "post");

        return yield put({ type: ADD_PODCAST_TO_USER_SUCCESS, podcastPermission: data });
    } catch (e) {
        if (e && e.stack && e.message && e.response) {
            const error: AxiosError = e.response;
            return yield put({
                type: ADD_PODCAST_TO_USER_FAILED,
                message: error?.message,
                status: error.response?.status,
            });
        }
    }
}

function* refreshUserSubscriptions({ userAccountId }: RefreshSubscriptions): any {
    try {
        yield call(createAxiosRequest, `api/subscription/refresh/${userAccountId}`, "", "post");

        return yield put<RefreshSubscriptionsSuccess>({
            type: REFRESH_SUBSCRIPTIONS_SUCCESS,
            message: "Subscriptions refreshed successfully",
            showToastSuccessMessage: true,
        });
    } catch (e) {
        if (e && e.stack && e.message && e.response) {
            const error: AxiosError = e.response;
            return yield put({
                type: REFRESH_SUBSCRIPTIONS_FAILED,
                message: error?.message,
                status: error.response?.status,
            });
        }
    }
}

function* unmigrateUser({ userAccountId }: Unmigrate): any {
    try {
        yield call(createAxiosRequest, `api/user-account/unmigrate/${userAccountId}`);

        return yield put({ type: UNMIGRATE_SUCCESS });
    } catch (e) {
        if (e && e.stack && e.message && e.response) {
            const error: AxiosError = e.response;
            return yield put({
                type: UNMIGRATE_FAILED,
                message: error?.message,
                status: error.response?.status,
            });
        }
    }
}

function* migrateUser({
    masterAccountId,
    schibstedUserAccountId,
}: MigrateUser): SagaReturnType<PutEffect<MigrateUserSuccess | MigrateUserFailed>> {
    try {
        yield call(createPostAxiosRequest, `api/user-account/${masterAccountId}/migrate`, {
            schibstedUserAccountId,
        });

        return yield put({ type: MIGRATE_USER_SUCCESS });
    } catch (e) {
        const defaultMessage = "Could not migrate user. Please try again.";
        const message = getErrorMessage(e, defaultMessage);

        return yield put<MigrateUserFailed>({
            type: MIGRATE_USER_FAILED,
            message,
            showToastErrorMessage: true,
        });
    }
}

function* loginWithIdp({ region }: LoginWithSchibsted): any {
    try {
        sessionStorage.clear();
        localStorage.clear();

        initSchibsted(region);
        schibstedIdentity?.login({
            state: String(Math.floor(Math.random() * 10000)),
            scope: "openid email offline_access",
        });
    } catch (error) {
        return yield put<LoginWithSchibstedFailed>({
            type: LOGIN_WITH_SCHIBSTED_FAILED,
            errorResponse: "Something went wrong",
        });
    }
}
function* refreshSchibstedToken({ region }: RefreshSchibstedToken): any {
    try {
        const cookies = new Cookies();
        const cookie = cookies.get("podme_admincookie");

        const response = yield axios({
            method: "get",
            url: `${config.BACKEND_API_URL}/api/SchibstedLogin/RefreshToken?refreshToken=${cookie.refresh_token}&region=${region}`,
        });

        cookies.set("podme_admincookie", response.data, {
            path: "/",
            maxAge: parseInt(response.data.expires_in) + 60,
        });

        const expiry = new Date();
        expiry.setTime(new Date().getTime() + response.data.expires_in * 1000);
        var currentDate = new Date();
        var futureDate = new Date(currentDate.getTime() + response.data.expires_in * 1000 - 2000);
        localStorage.setItem("expToken", futureDate.toISOString());
        localStorage.setItem("loggedIn", "1");
    } catch (error) {
        return yield put<LoginWithSchibstedFailed>({
            type: LOGIN_WITH_SCHIBSTED_FAILED,
            errorResponse: "Something went wrong",
        });
    }
}

function* getSchibstedToken({ code, codeVerifier, region }: GetSchibstedToken): any {
    try {
        const response = yield axios({
            method: "get",
            url: `${config.BACKEND_API_URL}/api/SchibstedLogin/GetToken?code=${code}&codeVerifier=${codeVerifier}&region=${region}`,
        });

        const expiry = new Date();
        expiry.setTime(new Date().getTime() + response.data.expires_in * 1000);
        var currentDate = new Date();
        var futureDate = new Date(currentDate.getTime() + response.data.expires_in * 1000 - 2000);
        const cookies = new Cookies();
        cookies.set("podme_admincookie", response.data, {
            path: "/",
            maxAge: parseInt(response.data.expires_in) + 60,
        });
        localStorage.setItem("loggedIn", "1");
        localStorage.setItem("expToken", futureDate.toISOString());

        return yield put({ type: types.LOGIN_USER_SUCCESS, userLoggedInWithSchibsted: true });
    } catch (error) {
        return yield put<LoginWithSchibstedFailed>({
            type: LOGIN_WITH_SCHIBSTED_FAILED,
            errorResponse: "Something went wrong",
        });
    }
}

function* refundTransaction({ transactionId, userAccountId, subscriptionId }: RefundTransaction): any {
    try {
        const response = yield call(createPostAxiosRequest, `api/subscription/refund-transaction/${transactionId}`, undefined);

        return yield put<RefundTransactionSuccess>({
            type: REFUND_TRANSACTION_SUCCESS,
            transactionId,
            userAccountId,
            subscriptionId,
            refundedAmount: response.data.refundedAmount,
            currency: response.data.currency,
            refundedAt: response.data.refundedAt,
            message: `Refund scheduled successfully`,
            showToastSuccessMessage: true,
        });
    } catch (e: unknown) {
        const defaultMessage = "Could not refund transaction.";
        const message = getErrorMessage(e, defaultMessage);

        return yield put<RefundTransactionFailed>({
            type: REFUND_TRANSACTION_FAILED,
            message,
            userAccountId,
            subscriptionId,
            transactionId,
            showToastErrorMessage: true,
        });
    }
}

function* fetchPaymentTransactions({ subscriptionId }: FetchPaymentTransactions): any {
    try {
        const response = yield call(
            createAxiosRequest,
            `api/subscription/get-vipps-mobile-pay-transactions?subscriptionId=${subscriptionId}`,
            "",
            "get"
        );

        return yield put<FetchPaymentTransactionsSuccess>({
            type: FETCH_PAYMENT_TRANSACTIONS_SUCCESS,
            data: response.data.vippsMobilePayTransactions,
            subscriptionId,
        });
    } catch (err: unknown) {
        return yield put<FetchPaymentTransactionsFailed>({ type: FETCH_PAYMENT_TRANSACTIONS_FAILED });
    }
}

function* fetchSubscriptionGuestHistory({ subscriptionId, userId }: FetchSubscriptionGuestHistory): any {
    try {
        const response = yield call(
            createAxiosRequest,
            `api/subscription/${subscriptionId}/subscription-guest-history/${userId}`,
            "",
            "get"
        );

        return yield put<FetchSubscriptionGuestHistorySuccess>({
            type: FETCH_SUBSCRIPTION_GUEST_HISTORY_SUCCESS,
            data: response.data.subscriptionGuestHistoryList,
            subscriptionId,
        });
    } catch (err: unknown) {
        return yield put<FetchSubscriptionGuestHistoryFailed>({ type: FETCH_SUBSCRIPTION_GUEST_HISTORY_FAILED });
    }
}

function* fetchSubscriptionEvents({
    subscriptionId,
    userAccountId,
}: FetchSubscriptionEvents): SagaReturnType<PutEffect<FetchSubscriptionEventsSuccess | FetchSubscriptionEventsFailed>> {
    try {
        const response = yield call(
            createAxiosRequest,
            `api/subscription/get-subscription-events?subscriptionId=${subscriptionId}`,
            "",
            "get"
        );
        const subscriptionEvents = response.data.subscriptionEventDetails.sort((a: SubscriptionEvent, b: SubscriptionEvent) =>
            a.date > b.date ? -1 : 1
        );

        return yield put<FetchSubscriptionEventsSuccess>({
            type: FETCH_SUBSCRIPTION_EVENTS_SUCCESS,
            data: subscriptionEvents,
            userAccountId,
            subscriptionId,
        });
    } catch (err: unknown) {
        return yield put<FetchSubscriptionEventsFailed>({ type: FETCH_SUBSCRIPTION_EVENTS_FAILED });
    }
}

// This function is used to fetch subscription events for all subscriptions of a user sequentially
function* fetchSubscriptionEventsSequentially({ subscriptionIds, userAccountId }: StartFetchSubscriptionEventsSequence) {
    for (const subscriptionId of subscriptionIds) {
        const fetchSubscriptionEventsAction: FetchSubscriptionEvents = {
            type: FETCH_SUBSCRIPTION_EVENTS,
            subscriptionId,
            userAccountId,
        };
        yield put(fetchSubscriptionEventsAction);
        yield take([FETCH_SUBSCRIPTION_EVENTS_SUCCESS, FETCH_SUBSCRIPTION_EVENTS_FAILED]);
    }
}

function* fetchPaymentTransactionsSequentially({ subscriptionIds }: StartFetchPaymentTransactionsSequence) {
    for (const subscriptionId of subscriptionIds) {
        const fetchPaymentTransactionAction: FetchPaymentTransactions = { type: FETCH_PAYMENT_TRANSACTIONS, subscriptionId };
        yield put(fetchPaymentTransactionAction);
        yield take([FETCH_PAYMENT_TRANSACTIONS_SUCCESS, FETCH_PAYMENT_TRANSACTIONS_FAILED]);
    }
}

function* fetchSubscriptionGuestHistorySequentially({ subscriptionIds, userId }: StartFetchSubscriptionGuestHistorySequence) {
    for (const subscriptionId of subscriptionIds) {
        const fetchSubscriptionGuestHistoryAction: FetchSubscriptionGuestHistory = {
            type: FETCH_SUBSCRIPTION_GUEST_HISTORY,
            subscriptionId,
            userId,
        };
        yield put(fetchSubscriptionGuestHistoryAction);
        yield take([FETCH_SUBSCRIPTION_GUEST_HISTORY_SUCCESS, FETCH_SUBSCRIPTION_GUEST_HISTORY_FAILED]);
    }
}

function* toggleSoftMigration({
    userId,
    softMigrationEnabled,
}: ToggleSoftMigration): SagaReturnType<PutEffect<ToggleSoftMigrationSuccess | ToggleSoftMigrationFailed>> {
    try {
        if (softMigrationEnabled) {
            yield call(createPostAxiosRequest, `api/user-account/soft-migration-allowed-user-accounts/${userId}`, undefined);
        } else {
            yield call(createDeleteAxiosRequest, `api/user-account/soft-migration-allowed-user-accounts/${userId}`);
        }

        return yield put({ type: TOGGLE_SOFT_MIGRATION_SUCCESS, softMigrationEnabled });
    } catch (e) {
        const defaultMessage = "Could not toggle soft migration status. Please try again.";
        const errorResponse = e && e.response ? new ApiError(e.response) : e;
        const message = errorResponse.message || defaultMessage;

        return yield put<ToggleSoftMigrationFailed>({
            type: TOGGLE_SOFT_MIGRATION_FAILED,
            message,
            showToastErrorMessage: true,
        });
    }
}

function* getSofMigrationStatus({
    userId,
}: GetSoftMigrationStatus): SagaReturnType<PutEffect<GetSoftMigrationStatusSuccess | GetSoftMigrationStatusFailed>> {
    try {
        const { data } = yield call(createAxiosRequest, `api/user-account/soft-migration-allowed-user-accounts/${userId}`);

        return yield put({ type: GET_SOFT_MIGRATION_STATUS_SUCCESS, softMigrationEnabled: data });
    } catch (e) {
        const defaultMessage = "Could not get the newest soft migration status. Please try again.";
        const message = getErrorMessage(e, defaultMessage);

        return yield put<GetSoftMigrationStatusFailed>({
            type: GET_SOFT_MIGRATION_STATUS_FAILED,
            message,
            showToastErrorMessage: true,
        });
    }
}

function getErrorMessage(error: any, defaultMessage: string): string {
    const errorResponse = error && error.response ? new ApiError(error.response) : error;

    return errorResponse.message || defaultMessage;
}

function* addFreeVoucherSubscription({ userId, voucherCode }: AddFreeSubscriptionValues): any {
    try {
        var body = { voucherCode: voucherCode };
        const response = yield call(createPostAxiosRequest, `api/subscription/apply-free-voucher-subscription/${userId}`, body);
        yield put({ type: GET_USER_DETAILS, userId });
        return yield put({ type: ADD_FREE_SUBSCRIPTION_SUCCESS, showToastSuccessMessage: true });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error: AxiosError = e.response;
            return yield put({
                type: ADD_FREE_SUBSCRIPTION_FAILED,
                message: error.message ? error.message : "Please check the voucher code and try again.",
                status: error.response?.status,
                showToastErrorMessage: true,
            });
        }
    }
}

export default [
    takeLatest(GET_USERNAME, getUsername),
    takeLatest(GET_USERS, getUsers),
    takeLatest(GET_USER_DETAILS, getUser),
    takeLatest(SEARCH_USER, searchUser),
    takeLatest(GET_USER_QUERY_TYPES, getUserQueryTypes),
    takeLatest(DELETE_USER, deleteUser),
    takeLatest(GET_USER_ROLE, getUserRole),
    takeLatest(CANCEL_SUBSCRIPTION, cancelSubscription),
    takeLatest(ADD_PODCASTER_PERMISSION, addPodcasterPermission),
    takeLatest(DELETE_PODCASTER_PERMISSION, deletePodcasterPermission),
    takeLatest(UPDATE_PODCAST_STATISTICS_PERMISSION, updatePodcastStatisticsPermission),
    takeLatest(ADD_PODCAST_TO_USER, addUserToPodcast),
    takeLatest(DELETE_PODCAST_FROM_USER, deletePodcastFromUser),
    takeLatest(REFRESH_SUBSCRIPTIONS, refreshUserSubscriptions),
    takeLatest(UNMIGRATE, unmigrateUser),
    takeLatest(MIGRATE_USER, migrateUser),
    takeLatest(LOGIN_WITH_SCHIBSTED, loginWithIdp),
    takeLatest(GET_SCHIBSTED_TOKEN, getSchibstedToken),
    takeLatest(REFRESH_SCHIBSTED_TOKEN, refreshSchibstedToken),
    takeEvery(FETCH_SUBSCRIPTION_EVENTS, fetchSubscriptionEvents),
    takeEvery(FETCH_PAYMENT_TRANSACTIONS, fetchPaymentTransactions),
    takeEvery(FETCH_SUBSCRIPTION_GUEST_HISTORY, fetchSubscriptionGuestHistory),
    takeLatest(TOGGLE_SOFT_MIGRATION, toggleSoftMigration),
    takeLatest(GET_SOFT_MIGRATION_STATUS, getSofMigrationStatus),
    takeEvery(START_FETCH_SUBSCRIPTION_EVENTS_SEQUENCE, fetchSubscriptionEventsSequentially),
    takeEvery(START_FETCH_PAYMENT_TRANSACTIONS_SEQUENCE, fetchPaymentTransactionsSequentially),
    takeEvery(START_FETCH_SUBSCRIPTION_GUEST_HISTORY_SEQUENCE, fetchSubscriptionGuestHistorySequentially),
    takeEvery(ADD_FREE_SUBSCRIPTION, addFreeVoucherSubscription),
    takeEvery(REFUND_TRANSACTION, refundTransaction),
];
