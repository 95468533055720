import { Flex, Heading, Table, Tbody, Td, Text, Th, Thead, Tr, Link, Button, Box } from "@chakra-ui/react";
import { MultimemberSubscriptionGuestResponse } from "../../redux/types";
import { format } from "date-fns";

interface Props {
    subscriptionGuestResponse: MultimemberSubscriptionGuestResponse | null;
}

function SubscriptionGuests({ subscriptionGuestResponse }: Props) {
    if (!subscriptionGuestResponse) return <></>;

    const { allowedNumberOfGuests, guests } = subscriptionGuestResponse;

    return (
        <Flex direction="column" gridGap="1rem">
            <Heading as="h3" fontSize="1.25rem" fontWeight="700">
                Duo Guest ({`${guests.length}/${allowedNumberOfGuests}`})
            </Heading>
            <Flex direction="column" gridGap="0.5rem">
                <Table borderColor="#cfcfcf">
                    <Thead fontSize="sm">
                        <Tr>
                            <Th textAlign="left" background="#cfcfcf">
                                <Text>User Id</Text>
                            </Th>
                            <Th textAlign="left" background="#cfcfcf">
                                <Text>Joined At</Text>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody fontSize="sm">
                        {guests?.map((guest, idx) => {
                            return (
                                <Tr key={idx}>
                                    <Td textAlign="left" borderColor="#cfcfcf" borderTop="1px" borderBottom="1px">
                                        <Link href={`/users/${guest.userId}`}>
                                            <Text
                                                as="span"
                                                fontWeight="500"
                                                fontSize="0.75rem"
                                                color={"#256199"}
                                                textTransform="uppercase"
                                            >
                                                {guest.userId}
                                            </Text>
                                        </Link>
                                    </Td>

                                    <Td textAlign="left" borderColor="#cfcfcf" borderTop="1px" borderBottom="1px">
                                        <Text as="span" fontWeight="500" fontSize="0.75rem" textTransform="uppercase">
                                            {format(new Date(guest.joinedAt), "yyyy-MM-dd HH:mm")}
                                        </Text>
                                    </Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
                {guests.length === 0 && (
                    <Box textAlign={"center"}>
                        <Text>No guests have been added to the subscription yet.</Text>
                    </Box>
                )}
            </Flex>
        </Flex>
    );
}

export default SubscriptionGuests;
